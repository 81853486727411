
import SettingsService from "@/servies/settings.service";
import { Duration, WorkDay, WORK_DAYS } from "@/types";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonItem,
  IonLabel,
  IonInput,
  IonDatetime,
  IonSelect,
  IonSelectOption
} from "@ionic/vue";
import { defineComponent } from "vue";

function durationToFakeDate(duration: Duration): Date {
  return new Date(0, 0, 0, duration.isNegative ? duration.hours * -1 : duration.hours, duration.minutes);
}

function fakeDateToDuration(date: Date): Duration {
  return new Duration(date.getHours(),date.getMinutes(), date.getHours() < 0 || date.getMinutes() < 0);
}

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonItem,
    IonLabel,
    IonInput,
    IonDatetime,
    IonSelect,
    IonSelectOption
  },
  data() {
    return { 
      allWorkDays: WORK_DAYS,
      description: SettingsService.description,
      workTime: durationToFakeDate(SettingsService.worktime.value).toISOString(),
      breakTime: durationToFakeDate(SettingsService.breaktime.value).toISOString(),
      workDays: SettingsService.workdays
    }
  },
  watch: {
    description: function (newVal: string) {
      SettingsService.setDescription(newVal);
    },
    workTime: function (newVal: string) {
      SettingsService.setWorkTime(fakeDateToDuration(new Date(newVal)));
    },
    breakTime: function (newVal: string) {
      SettingsService.setBreakTime(fakeDateToDuration(new Date(newVal)));
    },
    workDays: function (newVal: WorkDay[]) {
      SettingsService.setWorkDays(newVal);
    },
  },
});
